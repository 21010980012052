<template>
  <li>
    <div class="menu-item__mobile-content">
      <a @click.stop="() => onMenuItemClick(menuItem)" :class="{
          'active': isMenuActive(menuItem.cat_ID)
        }">{{ menuItem.name }}</a>
      <span v-if="hasSubItems(menuItem)" @click.stop="() => arrowClick(menuItem.cat_ID)" class="arrow"></span>
    </div>
    <ul v-if="hasSubItems(menuItem) && isMenuActive(menuItem.cat_ID)" :class="{
            'active': isMenuActive(menuItem.cat_ID)
          }">
      <RecurciveMobileMenuItem v-for="subItem in menuItem.sub" :key="subItem.cat_ID" @menu-clicked="onMenuItemClick" @arrowClick="arrowClick" :item="subItem" :activeMenuItems="$props.activeMenuItems"></RecurciveMobileMenuItem>
    </ul>
  </li>
</template>
<script setup>
import { defineProps, defineEmits, computed } from 'vue'
const props = defineProps({
  item: {
    item: Object
  },
  activeMenuItems: {
    type: Array,
    required: true
  }
})
const emit = defineEmits(['menu-clicked', 'arrow-click'])
const hasSubItems = (item) => {
  if (!item || !item.sub) {
    return false
  }
  return item.sub && item.sub.length
}
const menuItem = computed(() => {
  return props.item
})
const isMenuActive = (id) => {
  if (!id || !props.activeMenuItems) {
    return false
  }
  return props.activeMenuItems.includes(id)
}
const onMenuItemClick = (item) => {
  if (!item || !item.cat_ID) {
    return
  }
  emit('menu-clicked', item)
}
const arrowClick = (id) => {
  if (!id) {
    return
  }
  emit('arrow-click', id)
}

</script>
