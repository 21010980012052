<template>
  <router-view />
</template>

<script>

import 'swiper/css'
import { mapActions } from 'vuex'

export default {
  name: 'App',
  watch: {
    '$i18n.locale' (lang) {
      if (this.$i18n.locale === 'en') {
        window.history.pushState('object or string', 'Title', '/en')
      } else {
        window.history.pushState('object or string', 'Title', '/')
      }

      this.setLanguage(lang)
    }
  },
  created () {
    this.setLanguage(this.$i18n.locale)
    this.fetchContacts()
  },
  methods: {
    ...mapActions(['setLanguage', 'fetchContacts'])
  }
}
</script>
