<template>
  <section class="gallery" id="gallery">
    <div class="container">
      <div class="gallery-wrapper">
        <div class="gallery-tabs">
          <div class="gallery-tabs__item active" style="cursor: default">{{ $t('photoTitle') }}</div>
        </div>
        <div class="gallery-items">
          <div class="gallery-item">
            <div class="gallery-item__flex">
              <div class="gallery-item__block">
                <a class="gallery-item__link" href="https://wp.phbar.ru/wp-content/uploads/2022/10/dscf6470.jpeg"
                   data-fancybox="gallery">
                  <picture>
                    <source
                      srcset="https://wp.phbar.ru/wp-content/webp-express/webp-images/uploads/2022/10/dscf6470.jpeg.webp"
                      type="image/webp">
                    <img src="https://wp.phbar.ru/wp-content/uploads/2022/10/dscf6470.jpeg" alt=""
                         class="lazyload webpexpress-processed" loading="lazy">
                  </picture>
                </a>
              </div>
              <div class="gallery-item__block">
                <a class="gallery-item__link" href="https://wp.phbar.ru/wp-content/uploads/2022/10/dscf6458.jpeg"
                   data-fancybox="gallery">
                  <picture>
                    <source
                      srcset="https://wp.phbar.ru/wp-content/webp-express/webp-images/uploads/2022/10/dscf6458.jpeg.webp"
                      type="image/webp">
                    <img src="https://wp.phbar.ru/wp-content/uploads/2022/10/dscf6458.jpeg" alt=""
                         class="lazyload webpexpress-processed" loading="lazy">
                  </picture>
                </a>
              </div>
              <div class="gallery-item__block">
                <a class="gallery-item__link"
                   href="https://wp.phbar.ru/wp-content/uploads/2022/10/img_9236-scaled-e1666082013631.jpeg"
                   data-fancybox="gallery">
                  <picture>
                    <source
                      srcset="https://wp.phbar.ru/wp-content/webp-express/webp-images/uploads/2022/10/img_9236-scaled-e1666082013631.jpeg.webp"
                      type="image/webp">
                    <img src="https://wp.phbar.ru/wp-content/uploads/2022/10/img_9236-scaled-e1666082013631.jpeg" alt=""
                         class="lazyload webpexpress-processed" loading="lazy">
                  </picture>
                </a>
              </div>
              <div class="gallery-item__block">
                <a class="gallery-item__link" href="https://wp.phbar.ru/wp-content/uploads/2022/10/dscf6462.jpeg"
                   data-fancybox="gallery">
                  <picture>
                    <source
                      srcset="https://wp.phbar.ru/wp-content/webp-express/webp-images/uploads/2022/10/dscf6462.jpeg.webp"
                      type="image/webp">
                    <img src="https://wp.phbar.ru/wp-content/uploads/2022/10/dscf6462.jpeg" alt=""
                         class="lazyload webpexpress-processed" loading="lazy">
                  </picture>
                </a>
              </div>
              <div class="gallery-item__block">
                <a class="gallery-item__link"
                   href="https://wp.phbar.ru/wp-content/uploads/2022/10/img_9235-scaled-e1666082115755.jpeg"
                   data-fancybox="gallery">
                  <picture>
                    <source
                      srcset="https://wp.phbar.ru/wp-content/webp-express/webp-images/uploads/2022/10/img_9235-scaled-e1666082115755.jpeg.webp"
                      type="image/webp">
                    <img src="https://wp.phbar.ru/wp-content/uploads/2022/10/img_9235-scaled-e1666082115755.jpeg" alt=""
                         class="lazyload webpexpress-processed" loading="lazy">
                  </picture>
                </a>
              </div>
              <div class="gallery-item__block">
                <a class="gallery-item__link" href="https://wp.phbar.ru/wp-content/uploads/2022/10/dscf6473.jpeg"
                   data-fancybox="gallery">
                  <picture>
                    <source
                      srcset="https://wp.phbar.ru/wp-content/webp-express/webp-images/uploads/2022/10/dscf6473.jpeg.webp"
                      type="image/webp">
                    <img src="https://wp.phbar.ru/wp-content/uploads/2022/10/dscf6473.jpeg" alt=""
                         class="lazyload webpexpress-processed" loading="lazy">
                  </picture>
                </a>
              </div>
              <div class="gallery-item__block">
                <a class="gallery-item__link" href="https://wp.phbar.ru/wp-content/uploads/2022/10/dscf6466.jpeg"
                   data-fancybox="gallery">
                  <picture>
                    <source
                      srcset="https://wp.phbar.ru/wp-content/webp-express/webp-images/uploads/2022/10/dscf6466.jpeg.webp"
                      type="image/webp">
                    <img src="https://wp.phbar.ru/wp-content/uploads/2022/10/dscf6466.jpeg" alt=""
                         class="lazyload webpexpress-processed" loading="lazy">
                  </picture>
                </a>
              </div>
              <div class="gallery-item__block">
                <a class="gallery-item__link" href="https://wp.phbar.ru/wp-content/uploads/2022/10/img_9465.jpeg"
                   data-fancybox="gallery">
                  <picture>
                    <source
                      srcset="https://wp.phbar.ru/wp-content/webp-express/webp-images/uploads/2022/10/img_9465.jpeg.webp"
                      type="image/webp">
                    <img src="https://wp.phbar.ru/wp-content/uploads/2022/10/img_9465.jpeg" alt=""
                         class="lazyload webpexpress-processed" loading="lazy">
                  </picture>
                </a>
              </div>
            </div>
<!--            <div class="gallery-item__slider swiper">-->
<!--              <div class="swiper-wrapper">-->
                <Swiper
                  class="gallery-item__slider"
                  :loop="true"
                  :slides-per-view="3"
                  :space-between="8"
                >
                  <SwiperSlide
                    class="swiper-slide menu-item"
                    v-for="item in swiperImg"
                    :key="item"
                  >
                    <a
                      class="gallery-item__sliderLink" data-fancybox="gallery-mobile"
                      :href="`https://wp.phbar.ru/wp-content/uploads/2022/10/${item}.jpeg`"
                    >
                      <picture>
                        <source :srcset="`https://wp.phbar.ru/wp-content/webp-express/webp-images/uploads/2022/10/${item}.jpeg.webp`" type="image/webp">
                        <img :src="`https://wp.phbar.ru/wp-content/webp-express/webp-images/uploads/2022/10/${item}.jpeg`"/>
                      </picture>
                    </a>
                  </SwiperSlide>
                </Swiper>
<!--              </div>-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Fancybox } from '@fancyapps/ui/dist/fancybox/fancybox.esm'

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      swiperImg: ['dscf6470', 'dscf6458', 'img_9236-scaled-e1666082013631', 'dscf6462', 'img_9235-scaled-e1666082115755', 'dscf6473', 'dscf6466', 'img_9465']
    }
  },
  beforeMount () {
    const fancyboxSettings = {
      hideScrollbar: true,
      infinite: true,
      dragToClose: false,
      trapFocus: false,
      placeFocusBack: false,
      autoFocus: false
    }

    Fancybox.bind('[data-fancybox="gallery"]', fancyboxSettings)
    Fancybox.bind('[data-fancybox="gallery-mobile"]', fancyboxSettings)
  },
  mounted () {
  },
  methods: {
  }
}
</script>
