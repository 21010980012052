<template>
  <HeaderLayout :without-banner="true" />
  <main class="mainError">
    <div class="container"><img class="mainError-img" src="/assets/img/404.png" alt="">
      <router-link class="mainError-btn" :to="'/' + getCurrentLang()">Перейти на главную</router-link>
    </div>
  </main>
  <TheFooter/>
</template>

<script>
import TheFooter from '@/layout/TheFooter.vue'
import HeaderLayout from '@/layout/HeaderLayout.vue'

export default {
  name: 'NotFoundView',
  components: { HeaderLayout, TheFooter },
  methods: {
    getCurrentLang () {
      return this.$i18n.locale === 'ru' ? '' : this.$i18n.locale
    }
  }
}
</script>
