import { createStore } from 'vuex'
import Axios from 'axios'

export default createStore({
  state: {
    selectedCategoryName: '',
    topMenuCategoryId: null,
    currentCategoryId: null,
    lastAddedToCart: null,
    currentLanguage: '',
    menuItems: null,
    dishes: [],
    countDishes: 0,
    categoryName: '',
    isFound: true,
    isLoading: false,
    isLoadingMoreDishes: false,
    cart: [],
    dishData: [],
    mobileMenu: false,
    mobileMenuTop: false,
    isSearchResult: false,
    currentMenuItemId: null,
    contacts: null
  },
  mutations: {
    SET_MOBILE_MENU_TOP_M: (state, payload) => {
      state.mobileMenuTop = payload
    },
    SET_CONTACTS: (state, payload) => {
      state.contacts = payload
    },
    SET_CURRENT_MENU_ITEM_ID_M: (state, payload) => {
      state.currentMenuItemId = payload
    },
    SET_MOBILE_MENU_M: (state, payload) => {
      state.mobileMenu = payload
    },
    SET_DISH_INFO_M: (state, payload) => {
      state.dishData = payload
    },
    SET_CATEGORYID_M: (state, payload) => {
      state.categoryId = payload
    },
    SET_DISHES_DATA_M: (state, payload) => {
      state.dishes = payload.list
      state.countDishes = payload.count
      state.isSearchResult = false
    },
    DEL_DISHES_M: (state) => {
      state.dishes = []
    },
    DEL_SUBCATEGORY_M: (state) => {
      state.subCategory = []
    },
    SET_CATEGORY_NAME_M: (state, name) => {
      state.selectedCategoryName = name
    },
    SET_ISFOUND_M: (state, value) => {
      state.isFound = value
      state.topMenuCategoryId = null
      state.currentCategoryId = null
      state.isSearchResult = true
    },
    ADD_CART_M: (state, value) => {
      state.lastAddedToCart = value
      state.cart.push(value)

      localStorage.setItem('cartData', JSON.stringify(state.cart))
    },
    REMOVE_FROM_CART: (state, id) => {
      const i = state.cart.map(item => item.dishId).indexOf(id)
      state.cart.splice(i, 1)

      localStorage.setItem('cartData', JSON.stringify(state.cart))
    },
    QUANTITY_CART_ITEM_M: (state, payload) => {
      if (payload.quantity <= 0) {
        state.cart.splice(payload.index, 1)
      } else {
        state.cart[payload.index].dishQuantity = payload.quantity
      }
    },
    MORE_DISHES_M: (state, payload) => {
      state.dishes = [...state.dishes, ...payload]
    },
    CLEAR_CART_M: (state) => {
      state.cart = []

      localStorage.removeItem('cartData')
    },
    SET_COUNT_DISHES_M: (state, payload) => {
      state.countDishes = payload
    },
    SET_TOP_MENU_CATEGORY_ID: (state, id) => {
      state.topMenuCategoryId = id
    },
    SET_CURRENT_CATEGORY_ID: (state, id) => {
      state.currentCategoryId = id
    },
    SET_LOADING: (state, value) => {
      state.isLoading = value
    },
    SET_IS_LOADING_MORE_DISHES: (state, value) => {
      state.isLoadingMoreDishes = value
    },
    LOAD_CART: (state, value) => {
      state.cart = value
    },
    SET_LANGUAGE: (state, value) => {
      state.currentLanguage = value
    },
    SET_MENU_ITEMS: (state, payload) => {
      state.menuItems = payload
    }
  },
  actions: {
    SET_MOBILE_MENU_TOP: (context, value) => {
      const body = document.querySelector('body')

      if (body.classList.contains('fixed')) {
        body.classList.remove('fixed')
      } else {
        body.classList.add('fixed')
      }

      context.commit('SET_MOBILE_MENU_TOP_M', value)
    },
    SET_MOBILE_MENU: (context, value) => {
      const body = document.querySelector('body')

      if (body.classList.contains('fixed')) {
        body.classList.remove('fixed')
      } else {
        body.classList.add('fixed')
      }

      context.commit('SET_MOBILE_MENU_M', value)
    },
    CLEAR_CART: async (context) => {
      context.commit('CLEAR_CART_M')
    },
    QUANTITY_CART_ITEM: async (context, data) => {
      context.commit('QUANTITY_CART_ITEM_M', data)
    },
    ADD_CART: async (context, data) => {
      context.commit('ADD_CART_M', data)
    },
    REMOVE_FROM_CART: async (context, id) => {
      context.commit('REMOVE_FROM_CART', id)
    },
    SET_DISHINFO: async (context, data) => {
      context.commit('SET_DISH_INFO_M', data)
    },
    SET_CATEGORYID: async (context, id) => {
      context.commit('SET_CATEGORYID_M', id)
    },
    setCurrentMenuItemId: (state, payload) => {
      state.commit('SET_CURRENT_MENU_ITEM_ID_M', payload)
    },
    setCurrentSubMenuItemId: (state, payload) => {
      state.commit('SET_CURRENT_SUBMENU_ITEM_ID_M', payload)
    },
    setCurrentSubSubMenuItemId: (state, payload) => {
      state.commit('SET_CURRENT_SUBSUBMENU_ITEM_ID_M', payload)
    },
    updateCurrentDishes: ({ dispatch, state }) => {
      dispatch('fetchDishes', state.currentCategoryId)
    },
    setMenuItems: ({ commit }, payload) => {
      commit('SET_MENU_ITEMS', payload)
    },
    updateCurrentCategoryName: ({ commit, state }) => {
      let url = 'https://wp.phbar.ru/wp-json/phbar/v1/category_id/'

      if (state.currentLanguage === 'en') {
        url = 'https://wp.phbar.ru/en/wp-json/phbar/v1/category_id/'
      }

      Axios.get(url + state.currentCategoryId)
        .then((response) => {
          commit('SET_CATEGORY_NAME_M', response.data.cat_name)
        })
        .catch(() => {
          commit('SET_CATEGORY_NAME_M', '')
        })
    },
    fetchContacts: async ({ commit, state }) => {
      const url = 'https://wp.phbar.ru/wp-json/wp/v2/pages/2657'
      Axios.get(url)
        .then((response) => {
          if (response.data && response.data.acf) {
            commit('SET_CONTACTS', response.data.acf)
          } else {
            commit('SET_CONTACTS', null)
          }
        })
        .catch(() => {
          commit('SET_CONTACTS', null)
        })
    },
    searchDishes: async ({ commit, state }, searchQuery) => {
      const query = {
        searchQuery,
        language: state.currentLanguage
      }

      const url = 'https://wp.phbar.ru/wp-json/phbar/v1/search/'

      commit('SET_LOADING', true)

      Axios.post(url, query)
        .then(({ data }) => {
          commit('SET_DISHES_DATA_M', {
            list: data.dishes,
            count: data.length
          })
          commit('SET_ISFOUND_M', true)
        })
        .catch((e) => {
          commit('SET_ISFOUND_M', false)
        })
        .finally(() => {
          commit('SET_LOADING', false)
        })
    },
    SET_ISFOUND: async (context) => {
      context.commit('SET_ISFOUND_M', true)
    },
    setTopMenuCategoryId: (context, id) => {
      context.commit('SET_TOP_MENU_CATEGORY_ID', id)
    },
    setCurrentCategoryId: (context, id) => {
      context.commit('SET_CURRENT_CATEGORY_ID', id)
    },
    setCategoryName: (context, name) => {
      context.commit('SET_CATEGORY_NAME_M', name)
    },
    loadMoreDishes: async (context) => {
      context.commit('SET_IS_LOADING_MORE_DISHES', true)

      const query = { offset: context.state.dishes.length, language: context.state.currentLanguage }
      const url = 'https://wp.phbar.ru/wp-json/phbar/v1/dishes/' + context.state.currentCategoryId
      const { data } = await Axios.post(url, query)
        .finally(() => { context.commit('SET_IS_LOADING_MORE_DISHES', false) })

      context.commit('MORE_DISHES_M', data.dishes)
    },
    fetchDishes: async ({ dispatch, commit, state }, id) => {
      const query = { language: state.currentLanguage }
      commit('SET_LOADING', true)
      const selectedMenu = state.menuItems ? state.menuItems.find(el => el.cat_ID === id) : null
      let firstSub = null
      if (selectedMenu && selectedMenu.sub && selectedMenu.sub.length > 0) {
        console.log('here1', state.currentCategoryId, state.currentMenuItemId)
        if (state.currentCategoryId === null || state.currentMenuItemId === null) {
          console.log('here2')
          firstSub = selectedMenu.sub[0]
          dispatch('setCategoryName', firstSub.name)
          dispatch('setCurrentCategoryId', firstSub.cat_ID)
          dispatch('setCurrentMenuItemId', firstSub.cat_ID)
          id = firstSub.cat_ID
        }
      }
      const url = 'https://wp.phbar.ru/wp-json/phbar/v1/dishes/' + id
      try {
        const { data } = await Axios.post(url, query)

        commit('SET_DISHES_DATA_M', {
          list: data.dishes,
          count: data.count.post_count
        })
      } finally {
        commit('SET_LOADING', false)
      }
    },
    fetchDefaultCategory: async ({ dispatch, state }) => {
      let url = 'https://wp.phbar.ru/wp-json/phbar/v1/actual_category/'

      if (state.currentLanguage === 'en') {
        url = 'https://wp.phbar.ru/en/wp-json/phbar/v1/actual_category/'
      }

      await Axios.get(url)
        .then((response) => {
          const id = response.data.term_id
          const catName = response.data.cat_name

          dispatch('setTopMenuCategoryId', id)
          dispatch('setCategoryName', catName)
          dispatch('fetchDishes', id)
          dispatch('setCurrentCategoryId', id)
        })
    },
    tryLoadCartFromStorage: (context) => {
      const savedData = JSON.parse(localStorage.getItem('cartData'))

      if (savedData) {
        context.commit('LOAD_CART', savedData)
      }
    },
    setLanguage: (context, lang) => {
      context.commit('SET_LANGUAGE', lang)
    }
  },
  getters: {
    getCartItemById: state => id => {
      return state.cart.find(el => el.dishId === id)
    },
    sumCart: state => {
      return state.cart.reduce((price, el) => price + Number(el.dishPrice) * Number(el.dishQuantity), 0)
    },
    getCartTotal: state => {
      return state.cart.reduce((total, el) => total + el.dishQuantity, 0)
    },
    currentMenuItemId: state => {
      return state.currentMenuItemId
    },
    getContacts: state => {
      return state.contacts
    },
    topMenuCategoryId: state => {
      return state.topMenuCategoryId
    },
    menuItems: state => {
      return state.menuItems
    }
  }
}
)
