<template>
  <div class="catalog-item">
    <a class="catalog-item__top" @click.stop="$emit('cartClick')">
      <div class="catalog-double__img double-img" :class="item.isVertical">
        <!-- add webp support -->
        <img class="double-back" :src="require('@/assets/blured.jpeg')"  alt="background">
        <img class="double-front"  :src="(item.img)+ '?webp'" alt="">
      </div>
      <b class="catalog-item__link"><span>{{ $t('morePopup') }}</span>
        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 9L5 5L1 1" stroke="white" stroke-linecap="round"></path>
        </svg>
      </b>
    </a>
    <div class="catalog-item__title">{{ item.title }}</div>
    <div class="catalog-item__weight">{{ item.weight }}</div>
    <div class="catalog-item__bottom">
      <div class="catalog-item__price">{{ (item.price && item.price > 0) ? item.price + '₽' : $t('onRequest')}} </div>
      <div class="catalog-item__right">
        <a class="catalog-item__basket" v-if="item.attr" @click.stop="$emit('cartClick')">{{ $t('cartBtn') }}</a>
        <AddCartBtn
          v-else
          :dishId="item.dish_id"
          :dishTitle="item.title"
          :dishImg="item.img"
          :dishPrice="item.price"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AddCartBtn from '@/components/AddCartBtn'
export default {
  name: 'CatalogItem',
  components: {
    AddCartBtn
  },
  props: {
    item: Object
  }
}
</script>
