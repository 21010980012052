<template>
  <HeaderLayout/>
  <TheBasket/>
  <TheCatalog/>
  <TheGallery/>
  <TheDelivery/>
  <TheParking/>
  <CatalogPopup/>
  <ToTopBtn/>
  <TheFooter/>
</template>

<script>
import TheGallery from '@/layout/TheGallery.vue'
import CatalogPopup from '@/components/CatalogPopup.vue'
import TheParking from '@/layout/TheParking.vue'
import TheDelivery from '@/layout/TheDelivery.vue'
import ToTopBtn from '@/components/ToTopBtn.vue'
import TheBasket from '@/components/TheBasket.vue'
import TheCatalog from '@/components/TheCatalog.vue'
import HeaderLayout from '@/layout/HeaderLayout.vue'
import TheFooter from '@/layout/TheFooter.vue'

export default {
  name: 'HomeView',
  components: {
    TheFooter,
    HeaderLayout,
    TheCatalog,
    TheBasket,
    ToTopBtn,
    TheDelivery,
    TheParking,
    CatalogPopup,
    TheGallery
  }
}
</script>
