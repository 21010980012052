import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import store from './store'

import { createI18n } from 'vue-i18n'
import { languages } from '../i18n'

import HomeView from '@/views/HomeView.vue'
import NotFoundView from '@/views/NotFoundView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/:lang(en)',
    name: 'HomeLang',
    component: HomeView
  },
  // Добавьте маршрут для страницы 404
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: NotFoundView
  }
]

const router = createRouter({
  routes: routes,
  history: createWebHistory()
})

const currentUrl = window.location.pathname
const messages = Object.assign(languages)
let locale = 'ru' // default locale

const urlSegments = currentUrl.split('/')
if (urlSegments.includes('en')) {
  locale = 'en'
}

const i18n = createI18n({
  locale: locale,
  legacy: true,
  messages
})

const app = createApp(App)

app.use(router)
app.use(i18n)
app.use(store)
app.mount('#app')
