<template>
  <div class="catalog-popup" id="catalog-popup">
    <div class="catalog-popup__flex">
      <div class="catalog-popup__img double-img" :class="dishData.isVertical">
        <img class="double-back" :src="require('@/assets/blured.jpeg')"  alt="background">
        <img class="double-front" :src="dishData.img" alt="">
      </div>
      <div class="catalog-popup__wrapper">
        <div class="catalog-popup__title">{{ dishData.title }}</div>
        <div class="catalog-popup__subtitle">
          <p>{{$t('compound')}}: {{ dishData.composition }}</p>
          <p>{{ dishData.weight }}</p>
        </div>
        <div v-if="dishData.attr">
          <div
            v-for="(attr, attrIndex) in dishData.attr"
            class="catalog-popup__item active"
            :class="{'error': uncheckedAttrs.includes(attrIndex)}"
            :key="attr.title_group">
            <div class="catalog-popup__itemTitle">{{ attr.title_group }}</div>
            <div class="catalog-popup__itemFlex" v-if="selectedAttrs">
              <label
                v-for="item in attr.data_attr"
                :key="item.attr_title"
                class="catalog-popup__itemRadio"
                :for="item.attr_title"
              >
                <span
                  :id="item.attr_title"
                  class="radio"
                  :class="{'radio--checked': item === selectedAttrs[attrIndex]}"
                  type="radio"
                  @click="() => onAttrSelect(item, attrIndex)"
                  :value="item"
                  :name="attr.title_group"
                  required
                  ref="radio"
                >{{ item.attr_title }}</span>
              </label>
            </div>
            <div
              class="catalog-popup__required-text"
              v-if="haveAttributes && uncheckedAttrs.includes(attrIndex)"
            >
              {{ $t('mustChoose') }}
            </div>
          </div>
        </div>
        <div class="catalog-item__bottom">
          <div class="catalog-item__price">
            <span v-if="!havePrice">{{$t('onRequest')}}</span>
            <span v-else-if="havePrice && !haveAttributes">{{ dishData.price }} ₽</span>
            <span v-else>
              {{ (!selectedAttrs || Math.max(selectedPrice, 0) === 0) ? dishData.price : selectedPrice }} ₽
            </span>
          </div>
          <AddCartBtn
            @click="highlightUnchecked"
            :dishId="dishId"
            :dishSubTitle="subTitle"
            :dishTitle="dishData.title"
            :dishImg="dishData.img"
            :dishPrice="dishData.price"
            :dishAttrs="selectedAttrs"
            :canAddToCard="allRequiredAttrsChecked"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddCartBtn from '@/components/AddCartBtn'
import { mapState } from 'vuex'

export default {
  name: 'catalogPopup',
  components: {
    AddCartBtn
  },
  watch: {
    dishData: {
      handler (value) {
        this.uncheckedAttrs = []
        this.haveAttributes = value.attr && value.attr.length > 0

        this.selectedPrice = 0
        if (this.haveAttributes) {
          let index = 0
          this.selectedAttrs = new Array(value.attr.length)
          this.$nextTick(() => {
            value.attr.forEach((attrs, attrsIndex) => {
              attrs.data_attr.forEach((data, dataIndex) => {
                if (data.default) {
                  this.$refs.radio.forEach((input, inputIndex) => {
                    if (inputIndex === index) {
                      input.click()
                    }
                  })
                }
                index++
              })
            })
          })
          this.allAttributesChecked = false
        }
      },
      deep: true
    }
  },
  data () {
    return {
      selectedAttrs: null,
      selectedPrice: 0,
      uncheckedAttrs: [],
      haveAttributes: false,
      allAttributesChecked: false
    }
  },
  methods: {
    highlightUnchecked () {
      if (!this.dishData.attr) return
      for (let i = 0; i < this.dishData.attr.length; i++) {
        if (!this.selectedAttrs[i] && this.dishData.attr[i].required) {
          if (this.uncheckedAttrs.indexOf(i) === -1) {
            this.uncheckedAttrs.push(i)
          }
        }
      }
    },
    onAttrSelect (attr, index) {
      if (this.selectedAttrs[index] === attr) {
        this.selectedAttrs[index] = null
        this.allAttributesChecked = false
      } else {
        this.selectedAttrs[index] = attr
      }

      if (!attr) return

      if (attr.attr_price && Number(attr.attr_price) > 0) {
        this.selectedPrice = attr.attr_price
      }

      if (this.uncheckedAttrs.length > 0) {
        const idx = this.uncheckedAttrs.indexOf(index)

        if (idx > -1) {
          this.uncheckedAttrs.splice(idx, 1)
        }
      }

      for (let i = 0; i < this.dishData.attr.length; i++) {
        if (!this.selectedAttrs[i]) {
          this.allAttributesChecked = false

          return
        }
      }

      this.allAttributesChecked = true
    }
    // toggleAttr (event, attrIndex) {
    //   if (event.target.checked) {
    //     this.selectedAttrs[attrIndex] = null
    //   }
    // }
  },
  computed: {
    ...mapState(['dishData']),
    havePrice: function () {
      return this.dishData.price && Number(this.dishData.price) > 0
    },
    attributesRequired: function () {
      if (!this.dishData?.attr) {
        return false
      }
      return this.dishData.attr[0].required
    },
    subTitle: function () {
      if (!this.haveAttributes) {
        return null
      }

      return this.selectedAttrs.map((attr) => attr?.attr_title || '').join(' · ')
    },
    dishId: function () {
      if (!this.haveAttributes) {
        return this.dishData.dish_id
      }
      return this.dishData.dish_id + this.selectedAttrs.map((attr) => attr?.attr_title || '').join('_')
    },
    allRequiredAttrsChecked: function () {
      let allRequiredAttrsChecked = true
      if (!this.selectedAttrs) return true
      if (!this.dishData.attr) return true
      this.dishData.attr.forEach((attr, i) => {
        if (!this.selectedAttrs[i] && attr.required) allRequiredAttrsChecked = false
      })
      return allRequiredAttrsChecked
    }
  }
}
</script>
